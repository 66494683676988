import './App.css';
import HomeScreen from './Components/HomeScreen.js'
import Contact from './Components/Contact';
import SignUp from './Components/Signup';
import Login from './Components/Login';
import Role from './Components/Role.js';
import Aboutus from './Components/Aboutus.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import "primereact/resources/themes/lara-light-cyan/theme.css";


function App() {
  return (

    <BrowserRouter>
    <div>
    <Routes>
      {/* Configuration des routes */}
      <Route path="/" element={<HomeScreen></HomeScreen>} />
      <Route path="/GetYourAdvice" element={<Contact></Contact>} />
      <Route path="/signup" element={<SignUp></SignUp>} />
      <Route path="/login" element={<Login></Login>} />
      <Route path="/ourrole" element={<Role></Role>} />
      <Route path="/aboutus" element={<Aboutus></Aboutus>} />
      </Routes>
    </div>
    </BrowserRouter>

  );
}

export default App;
