import React from 'react'

function Footer() {
  return (
    <div>
          {/* Contact us */}
          <div className='pl-40 pr-40 pb-20 pt-20 border-t-1/5 border-white '>
      <h2 className='text-4xl mt-5 mb-20'>Contact Us</h2>
      <div className='flex flex-row justify-between flex-wrap gap-10'>
        <div>
            <span className='text-3xl border-dotted border-b-3 border-r-amber-100'>We are online</span>
            <p className='mt-8 text-lg'>Phone: 123-456-7890</p>
            <p className='text-lg'><a href='mailto:ldccontact.info@gmail.com'>Email: info@ourcompany.com</a></p>
        </div>

        <div>
            <span className='text-3xl border-dotted border-b-3 border-r-amber-100'>Visit us</span>
            <p className='mt-8 text-lg'>Street name</p>
            <p className='text-lg'>Zip code, country</p>
        </div>

        <div>
            <span className='text-3xl border-dotted border-b-3 border-r-amber-100'>Office hours</span>
            <dl className='mt-8 text-lg'>
                <div className='flex flex-row gap-10'>
                    <dt>Mon - Fri</dt>
                    <dd>9:00 am - 5:00 pm</dd>
                </div>
                <div className='flex flex-row gap-10'>
                    <dt>Sat - Sun</dt>
                    <dd>Closed</dd>
                </div>
            </dl>
        </div>

      </div>

      </div>
    </div>
  )
}

export default Footer