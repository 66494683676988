import React, { useEffect } from 'react'
import Nav from './Nav'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Footer from './Footer'

function Aboutus() {

    useEffect(() => {
        AOS.init({duration : 2000});
    }, [])




  return (
    <div className='bg-[#041e42]'>
    <Nav></Nav>
    <div className='border-t-1/5 border-yellow-50 text-white pt-20'>
    
    
    {/* about us */}
      <div className='mb-32 pl-10 pr-10 sm:pl-20 sm:pr-20'>
        <h1 className='text-4xl mb-20'>About us</h1>
        <div className='text-lg'>
        <p className='text-justify mb-5'>Welcome to LDC ! We pride ourselves on being a leader in the field of consulting and law in the country, thanks to the extensive expertise of our partners, lawyers, and legal professionals. </p>
        <p className='text-justify'>Our team's unparalleled knowledge and dedication enable us to deliver exceptional service and achieve optimal outcomes for our clients.</p>
        </div>
      </div>
      <img src='https://cdn.pixabay.com/photo/2017/01/14/10/56/people-1979261_1280.jpg' alt='lawyers' className='w-screen h-3quart'/>
    
    
    
    {/* Our team */}
      <div className='mb-32 pl-10 pr-10 sm:pl-20 sm:pr-20'>
        <h2 className='text-4xl mb-20 mt-20'>Our Team</h2>
        <div className='text-lg'>
        <p className='text-justify mb-5'>Our team consists of experienced lawyers and legal experts who specialize in various areas of law, including civil law, criminal law, corporate law, and more.</p>
        <p className='text-justify'>With a wealth of knowledge and expertise, our team is committed to delivering personalized solutions tailored to meet the unique needs of each client.</p>
        </div>
      </div>

      {/* <img src='https://cdn.pixabay.com/photo/2018/04/24/14/07/purchase-3347053_1280.jpg' alt='values' className='w-screen h-3quart'/> */}

    {/* Our partners */}
      <div className='mb-32 pl-10 pr-10 sm:pl-20 sm:pr-20'>
        <h2 className='text-4xl mb-20 mt-20'>Our partners</h2>
        <div className='flex flex-row flex-wrap justify-between gap-10'>
            <div data-aos="fade-up">
                <img src='https://scontent-cdg4-3.xx.fbcdn.net/v/t39.30808-6/316323422_105592222383960_8767722225857578075_n.jpg?stp=dst-jpg_p526x296&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=bzAH_5PD6MAAb7HZ2kB&_nc_ht=scontent-cdg4-3.xx&oh=00_AfAy9N20Pre2GzOYBu8oaOGTPrliw0LOfqTND5WRdPG7Tg&oe=662EEA2C' className='w-30vh h-30vh mb-5 hover:scale-110 duration-500' alt='associe'/>
                <span className='text-3xl'>Eli Niri</span>
                <p className='text-lg mt-5'>Partner, Immigration Law</p>
            </div>

            <div data-aos="fade-up">
                <img src='https://scontent-cdg4-2.xx.fbcdn.net/v/t1.6435-9/31218576_10156081421376346_782495633482186752_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=E1PB6NIlmWkAb7wkhlO&_nc_ht=scontent-cdg4-2.xx&oh=00_AfAIrKM3YD21kyV8X_ScLvU0laELgQuGKm-RzKJYXGZRLQ&oe=66508128' className='w-30vh h-30vh mb-5 hover:scale-110 duration-500' alt='associe'/>
                <span className='text-3xl'>Antoine Gazzi</span>
                <p className='text-lg mt-5'>Partner, Divorce Law</p>
            </div>

            <div data-aos="fade-up">
                <img src='https://scontent-cdg4-3.xx.fbcdn.net/v/t39.30808-6/358416329_10231241685768039_8958298329033947551_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=5f2048&_nc_ohc=OzBLySBCS2UAb5_V4VU&_nc_ht=scontent-cdg4-3.xx&oh=00_AfCK7_klBfMnKUE9PgmPYpF5nKdzhK3aHii3BNozV-mDiw&oe=662EFEB6' className='w-30vh h-30vh mb-5 hover:scale-110 duration-500' alt='associe'/>
                <span className='text-3xl'>Dimitri El Afar</span>
                <p className='text-lg mt-5'>Partner, Immigration Law</p>
            </div>
        </div>
      </div>
    
    
    {/* Our values */}
      <div className='mb-32 pl-10 pr-10 sm:pl-20 sm:pr-20'>
      <h2 className='text-4xl mt-20 mb-20'>Our Values</h2>
      <div className='text-lg'>
      <ul className='text-justify'>
        <li className='mb-5'>Integrity: We conduct ourselves with the highest standards of integrity, professionalism, and ethical behavior, earning the trust and confidence of our clients.</li>
        <li className='mb-5'>Excellence: We strive for excellence in all aspects of our work, delivering outstanding results and achieving the best possible outcomes for our clients.</li>
        <li className='mb-5'>Client-Centricity: We are dedicated to understanding our clients' needs and objectives and providing them with pragmatic, solution-oriented advice and representation.</li>
        <li className='mb-5'>Empathy: We approach each client with empathy, compassion, and understanding, recognizing the challenges they may face and offering support and guidance every step of the way.</li>
        <li>Community Engagement: We are committed to giving back to our community through pro bono work, volunteer initiatives, and support for charitable organizations.</li>
      </ul>
      </div>
      </div>

      <img src='https://cdn.pixabay.com/photo/2020/03/09/18/09/book-4916655_1280.jpg' alt='values' className='w-screen h-3quart'/>

    {/* Our expertise */}
        <div className='mb-32 pl-10 pr-10 sm:pl-20 sm:pr-20'>
        <h2 className='text-4xl mb-20 mt-20'>Our Expertise</h2>
        <div className='flex flex-row flex-wrap justify-between gap-10'>
        <div className='w-30vh' data-aos="fade-up">
            <span className='text-4xl'>15</span>
            <p className='mt-8 text-lg text-justify'>Partners, lawyers, legal assistants, and articling students, all of whom come from top institutions worldwide.</p>
        </div>

        <div className='w-30vh' data-aos="fade-up">
            <span className='text-4xl'>2</span>
            <p className='mt-8 text-lg text-justify'>Domestic locations. We have offices in some of the country's largest cities, and a couple of small ones, too.</p>
        </div>

        <div className='w-30vh' data-aos="fade-up">
            <span className='text-4xl'>5</span>
            <p className='mt-8 text-lg text-justify'>Overseas partnerships. We have  international partners in several countries providing services in key areas. </p>
        </div>
        </div>

      </div>

      <Footer></Footer>
      
      </div>
    </div>
  )
}

export default Aboutus