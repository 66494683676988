import React, { useState, useEffect, useRef } from 'react'
import Nav from './Nav'
import GoogleMap from 'google-maps-react-markers'
import Marker from './Marker';
import {auth, db} from '../firebase'
import { collection,getDocs,query, where, addDoc, onSnapshot} from "firebase/firestore";
import Autosuggest from 'react-autosuggest';
import './Contact.css';
import axios from 'axios';




  const subjects = [
    {id: 0, name:"Droit International"},
    {id:1, name: "Tribunaux Internationaux"},
    {id:2, name:"Droit Maritime & Aérien"},
    {id:3, name:"Contrat International"},
    {id:4, name: "Droit Pénal"},
    {id:5, name: "Droit des Successions"},
    {id:6, name: "Droit de la Famille"},
    {id:7, name: "Droit Médical"},
    {id:8, name: "Droit des Affaires"},
    {id:9, name: "Droit du Travail"},
    {id:10, name: "Droit des Assurances"},
    {id:11, name: "Droit Bancaire"},
    {id:12, name: "Droit de L'immobilier"},
    {id:13, name: "Droit de la Propriété Intellectuelle"},
    {id:14, name: "Droit de L'environnement"},
    {id:15, name: "Droit Administratif"} 
  ];

  export default function Contact($hover){
    const [products, setProducts] = useState([]);
    const [subject , setSubject] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const mapRef = useRef(null)
    const [mapReady, setMapReady] = useState(false)
    const [formData, setFormData] = useState({
      name: '',
      email: auth.currentUser?.email,
      subject: subject,
      message: ''
    });

    const apiUrl = `https://mail.zoho.eu/api/accounts/${process.env.REACT_APP_ZOHO_ID}}/messages`;

    const requestBody = {
      fromAddress: 'no-reply@legal-defense-consultancy.com',
      toAddress: formData.email,
      subject: formData.subject,
      content: `Dear ${formData.name},

      Thank you for contacting us.
      
      This is an automated message to confirm that we have received your inquiry on 
      
      the following subject: ${formData.subject}. 
      
      Our team has seen your request and is currently reviewing it.
      
      We will respond to your inquiry as soon as possible. 
      
      Please allow us some time to provide you with a thorough and accurate response.
      
      Thank you for your patience and understanding.
      
      Best regards,
      LDC Customer Support Team
      
      Note: This is an automated email, please do not reply directly to this message.`,
      askReceipt: 'yes'
    };


    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };

      // You need to define your mapOptions and coordinates
  const mapOptions = {
    // Your map options
  };

  const coordinates = [
    { lat: 33.89346, lng: 35.56287, name: 'Marker 1' },
    // Add more coordinates as needed
  ];
  /**
   * @description This function is called when the map is ready
   * @param {Object} map - reference to the map instance
   * @param {Object} maps - reference to the maps library
   */
  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map
    setMapReady(true)
  }

  const onMarkerClick = (e, { markerId, lat, lng }) => {
    console.log('This is ->', markerId)

    // inside the map instance you can call any google maps method
    mapRef.current.setCenter({ lat, lng })
    // ref. https://developers.google.com/maps/documentation/javascript/reference?hl=it
  }



    useEffect(() => {

      const getProducts = async () => {
      try {
      const q = query(collection(db, "products"), where("active", "==", true));
      const querySnapshot = await getDocs(q);
      
      const products = {};
      querySnapshot.forEach(async (doc) => {
        console.log(doc.id, " => ", doc.data());
              // fetch prices subcollection per product
        const pricesCollection = collection(doc.ref, 'prices');
        const priceQuerySnapshot = await getDocs(pricesCollection);
              // assume there is only one price per product

        const priceDoc = priceQuerySnapshot.docs[0];
        products[doc.id] = doc.data();
        products['priceId'] = priceDoc.id;
        products['priceInfo'] = priceDoc.data();
      });
        // doc.data() is never undefined for query doc snapshots

      setProducts(products);

      }catch(err){

        console.log(err);
      }
      }
      getProducts();
      
    }, []);

    console.log("ici diligros" , products)

    // SEND EMAIL EMAIL JS + FIREBASE FUNCTION 
    /****************************************/

    const sendEmail = async (name, email, subject) => {
      const response = await fetch('https://us-central1-papa-app-b06bc.cloudfunctions.net/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, subject }),
      });
    
      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email');
      }
    };

    const loadcheckout = async (e) => {
      e.preventDefault();
    //   let checkoutSessionData = {
    //     price: 'price_1PL2nUGKxjmBgEAtFrZYZfi7', // price ID from products fetch
    //     success_url: window.location.origin, // can set this to a custom page
    //     cancel_url: window.location.origin   // can set this to a custom page
    // };
    
    // if payment is a one-time payment (as opposed to subscription)
    // append mode: 'payment' to the checkout session data
    // if (isOneTime) {
        //checkoutSessionData['mode'] = 'payment';
    // }
    
    // const checkoutSessionRef = await addDoc(
    //   // currentUser is provided by firebase, via getAuth().currentUser
    //   collection(db, `customers/${auth.currentUser?.uid}/checkout_sessions`), 
    //   checkoutSessionData
    // );
    
    // The Stripe extension creates a payment link for us
    // onSnapshot(checkoutSessionRef, async (snap) => {
    //   const { error, url } = snap.data();
    //   if (error) {
    //     // handle error
    //     alert(`An error occured: ${error.message}`);
    //   }
    //   if (url) {
    //     // we have a session, let's redirect to checkout
    //     //Init stripe
    //     window.location.assign(url);  // redirect to payment link
    //     // const stripe = await loadStripe("pk_live_51OWjMVGaWEWPGSYTJnhgQZFMiHk5owNhrg87d5qZ0Q5NcmZC3RPLrD8k4qhkcXjUDizmCiAMZkffW2qXy9rGn32V00MRkmUu0Y");
    //     // stripe.redirectToCheckout({url});
        //sendEmail(formData.name, formData.email, formData.subject);

        axios.post(apiUrl, requestBody)
  .then(response => {
    console.log('Email envoyé avec succès:', response.data);
  })
  .catch(error => {
    console.error('Erreur lors de l\'envoi de l\'email:', error);
  });
            
      }
    // });
    //}




    // useEffect(()=> {
    //     const getUserName = async () => {
    //         // console.log(auth.currentUser?.uid)
    //         const docRef = doc(db, "users", auth.currentUser?.uid);
    //         const docSnap = await getDoc(docRef);
    //         if (docSnap.exists()) {
    //             console.log("Document data:", JSON.stringify(docSnap?.data()));
    //             setUser(docSnap);
    //             console.log("user name: ", JSON.stringify(user?.data().name.name));
    //             // console.log("user.name : ", user?.name)
    //           } else {
    //             // docSnap.data() will be undefined in this case
    //             console.log("No such document!");
    //           }
    //     }
    //     return () => {
    //         getUserName();
    //     }
    
    // },[])

    return (
      // Important! Always set the container height explicitly
      <div className='h-screen'>
      <Nav></Nav>

      <div className='flex flex-col relative md:flex-row'>

                            {/* google map component */}
      <div className='mt-14 ml-20' style={{ height: '60vh', width: '50vw' }}>

          <GoogleMap
            apiKey={process.env.REACT_APP_GOOGLE_KEY}
            defaultCenter={{ lat: 33.89346, lng: 35.56287 }}  
            defaultZoom={19}
            options={mapOptions}
            mapMinHeight="30vh"
            onGoogleApiLoaded={onGoogleApiLoaded}
            onChange={(map) => console.log('Map moved', map)}
          >
            {coordinates.map(({ lat, lng, name }, index) => (
              <Marker
                key={index}
                lat={lat}
                lng={lng}
                markerId={name}
                onClick={onMarkerClick} // you need to manage this prop on your Marker component!
                // draggable={true}
                // onDragStart={(e, { latLng }) => {}}
                // onDrag={(e, { latLng }) => {}}
                // onDragEnd={(e, { latLng }) => {}}
              />
            ))}
          </GoogleMap>

      </div>



                                    {/* form */}

    <div className="flex items-center justify-center p-12 ml-20">

        <div className="mx-auto w-full max-w-[550px]">
            <form method="POST">
            <div className="mb-5">
                <h5 className='text-md text-[#2d922d] font-semibold tracking-wide'>For every consulting request will be applied a 5$ fee</h5>
            </div>
            <div className="mb-5">
                <label
                for="name"
                className="mb-3 block text-base font-medium text-[#07074D]"
                >
                Full Name
                </label>
                <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                id="name"
                // value={JSON.stringify(user?.data().name.name)}
                placeholder="Full Name"
                className="input"
                />
            </div>
            <div className="mb-5">
                <label
                for="email"
                className="mb-3 block text-base font-medium text-[#07074D]"
                >
                Email Address
                </label>
                <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="example@domain.com"
                className='input'
                />
            </div>
            <div className="mb-5">
                <label
                htmlFor="subject"
                className="mb-3 block text-base font-medium text-[#07074D]"
                >
                Subject
                </label>
                
                <div className='relative'>
                <Autosuggest
                  inputProps={{ // cible le input
                    placeholder: "Type to search...",
                    autoComplete: "abcd",
                    name: "subject",
                    id: "subject",
                    value: subject,
                    className: 'input_A',

                    onChange: (e, {newValue}) => {
                      setSubject(newValue);
                    }
                  }}
                  suggestions={subjects} // contient toutes les suggestions à afficher dans la liste déroulante 

                  // filtrer les suggestions en fonction de ce que l'utilisateur saisit
                  onSuggestionsFetchRequested={async ({value}) => { 
                    if(!value) {
                      setSuggestions(subjects);
                      return;
                    }
                    try {
                      // Filtrer les suggestions en fonction de la valeur saisie
                      const filteredSuggestions = subjects.filter(
                        suggestion => suggestion.name.toLowerCase().includes(value.toLowerCase())
                      );
                      setSuggestions(filteredSuggestions);
                    }  catch(e) {
                      setSuggestions([]);
                    }
                  }}

                  // pour effacer les suggestions lorsque l'entrée est vidée.
                  onSuggestionsClearRequested={() => {
                    setSuggestions(subjects);
                  }}

                  // Une fonction qui prend une suggestion de la liste et retourne la valeur 
                  //qui doit être affichée dans l'entrée lorsque cette suggestion est sélectionnée.
                  getSuggestionValue={suggestion => suggestion.name }
                  renderSuggestion={suggestion => <div className='whitespace-nowrap overflow-hidden overflow-ellipsis p-3 hover:bg-[#6ba9db] text-sm font-normal font-sans tracking-wider'>
                    {suggestion.name}</div>}
                  onSuggestionSelected={(e, {suggestion, method}) => {
                  if (method === "enter") {
                    e.preventDefault();
                  }
                  setSubject(suggestion.name);
                }}
                />
                <p  className='bg-blue-700'></p>
                  <button className='absolute top-2.5 left-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="#2563EB" class="w-3.5 h-3.5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                  </button>
                  </div>
            </div>
            <div className="mb-5">
                <label
                for="message"
                className="mb-3 block text-base font-medium text-[#07074D]"
                >
                Message
                </label>
                <textarea
                rows="4"
                name="message"
                value={formData.message}
                onChange={handleChange}
                id="message"
                placeholder="Type your message"
                className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#00457c] focus:shadow-md"
                ></textarea>
            </div>
            <div>
                <button onClick={loadcheckout}
                className="hover:shadow-form bg-[#00457c] py-3 px-8 text-base font-normal tracking-widest text-white outline-none"
                >
                Go to Payment
                </button>
            </div>
            </form>
        </div>
    </div>
    </div>
      </div>
    );
  }