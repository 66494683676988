import { onAuthStateChanged, signOut} from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import {auth} from '../firebase'
import './AuthDetails.css'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function AuthDetails() {


    const [authUser, setAuthUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if(user) {
                // if signed in
                setAuthUser(user);
                console.log ("user connected" , user);
            } else {
                setAuthUser(null);
                console.log("user connected null");
            }
        }
            );
            return () => {
                listen();
            }
    },[]);

    const userSignOut = () => {
        signOut(auth).then(() => {
            console.log('sign out successful')
            navigate('/');
        }).catch(error => console.log(error))
    }
  return (
    <div className='relative'>
        {authUser ? <><p className='tracking-wide font-sans font-medium text-white text-base'>{`Welcome ${authUser.email}`}</p><button className='auth_button' onClick={userSignOut}>Sign Out</button></> :<> <p className='tracking-wide font-sans font-medium text-white text-base'>No user Connected</p><Link to='/login'><p className='text-base font-sans text-[#00457c] hover:text-sky-950 font-bold tracking-wide mt-12'>Login</p></Link></>}
    </div>
  )
}

export default AuthDetails