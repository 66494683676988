import React, { useState } from 'react'
import Nav from './Nav'
import Footer from './Footer';

function Role() {
    
  const [arabic, setArabic] = useState(false);

  return (
    <div className='bg-[#041e42]'>
        <Nav></Nav>

        <div className='border-t-1/5 border-yellow-50 text-white pl-10 pr-10 pt-20 pb-40 sm:pl-40 sm:pr-40'>
            <h1 className='text-4xl pb-20'>Our role</h1>

            {!arabic && (
            <div className='text-justify'>
            <p className='text-lg tracking-wide mb-2'>Welcome to our online legal advice platform. We are here to offer you personalized and expert legal assistance in various areas of law.</p>
            <p className='text-lg tracking-wide mb-2'>Whether you have a question regarding medical law, sports law, family law, or any other legal field, our team of qualified lawyers is ready to help.</p>
            <p className='text-lg tracking-wide mb-2'>Simply choose the relevant legal category, ask your question, and we commit to providing you with a precise and comprehensive answer as quickly as possible.</p>
            <p className='text-lg tracking-wide mb-2'>With our online legal advice service, you can benefit from the expertise of a lawyer without the need to travel or schedule an appointment.</p>
            <p className='text-lg tracking-wide mb-2'>We are here to guide you through the complexities of the legal system and provide you with the guidance you need to make informed decisions.</p>
            <p className='text-lg tracking-wide mb-2'>Feel free to contact us for quality and professional legal assistance.</p>
            </div>
            )}

            {arabic && (
            <div className='text-justify'>
            <p className='text-lg tracking-wide mb-2'>.مرحبًا بكم في منصتنا عبر الإنترنت لتقديم الاستشارات القانونية. نحن هنا لنقدم لكم المساعدة القانونية المخصصة والمتخصصة في مختلف مجالات القانون</p>
            <p className='text-lg tracking-wide mb-2'>.سواء كان لديك سؤال يتعلق بقانون الطب، أو قانون الرياضة، أو قانون العائلة، أو أي مجال قانوني آخر، فإن فريقنا المؤهل من المحامين جاهز للمساعدة</p>
            <p className='text-lg tracking-wide mb-2'>.كل ما عليك فعله هو اختيار الفئة القانونية ذات الصلة، وطرح سؤالك، ونحن نلتزم بتقديم إجابة دقيقة وشاملة في أسرع وقت ممكن</p>
            <p className='text-lg tracking-wide mb-2'>.من خلال خدمتنا للاستشارات القانونية عبر الإنترنت، يمكنك الاستفادة من خبرة محامي دون الحاجة إلى السفر أو تحديد موعد</p>
            <p className='text-lg tracking-wide mb-2'>.نحن هنا لنرشدكم خلال تعقيدات النظام القانوني ونقدم لكم الإرشاد الذي تحتاجونه لاتخاذ القرارات المستنيرة</p>
            <p className='text-lg tracking-wide mb-2'>.لا تترددوا في الاتصال بنا للحصول على مساعدة قانونية ذات جودة واحترافية</p>
            </div>
            )}
            
            <div className='flex flex-row gap-10 mt-10'>
            <button className={arabic ? '' : 'border-b-3 border-b-amber-600'} onClick={() => setArabic(false)}>english</button>
            <button className={arabic? 'border-b-3 border-b-amber-600' : ''} onClick={() => setArabic(true)}>العربية</button>
            </div>

        </div>
        <Footer></Footer>
    </div>
  )
}

export default Role