import React, { useEffect, useState } from 'react'
import { createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import { auth, db } from '../firebase';
import { setDoc, doc } from "firebase/firestore"; 
import { Link } from 'react-router-dom'
import Nav from './Nav';
import './Signup.css'


function SignUp() {
    const [name, setName] = useState("");
    const [email,setMail] = useState("");
    const [chargement, setChargement] = useState("");
    const [password, setPassword] = useState("");
    const [mailAlreadyUsed, setMailAlreadyUsed] = useState(false);
    const [missingPassword, setMissingPassword] = useState(false);
    const [weakPassword, setWeakPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);


    // useEffect(() => {

    //   // Observer les changements d'état d'authentification
    //   const unsubscribe = onAuthStateChanged(auth, (user) => {
    //     if (user && user.emailVerified) {
    //       // L'e-mail de l'utilisateur est vérifié, rediriger vers une autre page
    //       navigate('/GetYourAdvice'); 
    //     }
    //   });
  
    //   return () => {
    //     // Nettoyer l'observateur lors du démontage du composant
    //     unsubscribe();
    //   };
    // },[auth.currentUser, navigate]);

    useEffect(() => {
      console.log("auth.currentUser : ", auth.currentUser);
    })

    const Signup = (e) => {
    e.preventDefault();
    setMailAlreadyUsed(false);
    setMissingPassword(false);
    setWeakPassword(false);

    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
    // Signed up 
    // on rajoute le user a la collection users
    const addUser = async () => {
        try {
    await setDoc(doc(db, "users", userCredential.user.uid), {name: {name}, type : "client"});
        }catch(err){
            console.error(err);
        }
    };
    addUser(); 

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for
      // this URL must be whitelisted in the Firebase Console.
      url: 'https://legal-defense-consultancy.com/GetYourAdvice',
      // This must be true for email link sign-in.
      handleCodeInApp: true,
    };
    sendEmailVerification(auth.currentUser, actionCodeSettings)
    .then(() => {
      // Email verification sent!
      // ...
      //  while(!auth.currentUser.emailVerified===true)
      //  {
      //    setChargement(true);
      //    console.log("en chargement...");
      //  }
       //console.log("chargement fini !")
      setChargement(true);
      console.log("emailVerified : ", auth.currentUser.emailVerified);
    });
  })
  .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
            setMailAlreadyUsed(true);
        }
        if (error === "FirebaseError: Firebase: Error (auth/missing-password).")
        {
            setMissingPassword(true);
        }
        if (error === "FirebaseError: Firebase: Password should be at least 6 characters (auth/weak-password).")
        {
            setWeakPassword(true);
        }

    console.log("error : ", error);
    });
                        }
    
  return (

    <div className='bg-gray-200'>

    {!chargement && (
    <div>
    <Nav></Nav>
    <div className="min-h-screen flex flex-col items-center mt-10">

    <div
      className="
        flex flex-col
        bg-white
        shadow-md
        px-4
        sm:px-6
        md:px-8
        lg:px-10
        py-8
        rounded-3xl
        w-50
        max-w-md
      "
    >
      <div className="font-medium self-center text-xl sm:text-3xl text-gray-800">
        Join us Now
      </div>
      <div className="mt-4 self-center text-xl sm:text-sm text-gray-800">
        Enter your credentials to get access to an account
      </div>

      <div className="mt-10">
        <form onSubmit={Signup}>
          <div className="flex flex-col mb-5">
            <label
              className="mb-1 text-xs tracking-wide text-gray-600"
              >Name:</label
            >
            <div className="relative">
              <div
                className="
                  inline-flex
                  items-center
                  justify-center
                  absolute
                  left-0
                  top-0
                  h-full
                  w-10
                  text-gray-400
                "
              >
              </div>

              <input
                type='text'
                value={name}
                onChange={(e)=> setName(e.target.value)}
                className="
                  text-sm
                  placeholder-gray-500
                  pl-10
                  pr-4
                  rounded-2xl
                  border border-gray-400
                  w-full
                  py-2
                  focus:outline-none focus:border-blue-500
                "
                placeholder="Enter your name"
              />
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <label
              className="mb-1 text-xs tracking-wide text-gray-600"
              >E-Mail Address:</label
            >
            <div className="relative">
              <div
                className="
                  inline-flex
                  items-center
                  justify-center
                  absolute
                  left-0
                  top-0
                  h-full
                  w-10
                  text-gray-400
                "
              >

              </div>

              <input
                type="email"
                value={email}
                onChange={(e)=> setMail(e.target.value)}
                className="
                  text-sm
                  placeholder-gray-500
                  pl-10
                  pr-4
                  rounded-2xl
                  border border-gray-400
                  w-full
                  py-2
                  focus:outline-none focus:border-blue-500
                "
                placeholder="Enter your email"
              />
            </div>
          </div>
          <div className="flex flex-col mb-6">
            <label
              className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
              >Password:</label
            >
            <div className="relative">
              <div
                className="
                  inline-flex
                  items-center
                  justify-center
                  absolute
                  left-0
                  top-0
                  h-full
                  w-10
                  text-gray-400
                "
              >

              </div>

              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e)=> setPassword(e.target.value)}
                className="
                  text-sm
                  placeholder-gray-500
                  pl-10
                  pr-4
                  rounded-2xl
                  border border-gray-400
                  w-full
                  py-2
                  focus:outline-none focus:border-blue-500
                  relative
                "
                placeholder="Enter your password"
              />
              <button onClick={() => {setShowPassword(!showPassword)}}   className='cursor-pointer absolute right-4 top-1/2 -translate-y-1/2'>
                { showPassword &&(
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9CA3AF" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>)}
                { !showPassword && (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9CA3AF" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg>)}
              </button>
            </div>
          </div>

          <div className="flex w-full">
            <button
              type="submit"
              className="
                flex
                mt-2
                items-center
                justify-center
                focus:outline-none
                text-white text-sm
                sm:text-base
                bg-[#00457c]
                hover:bg-[#041e42]
                rounded-2xl
                py-2
                w-full
                transition
                duration-150
                ease-in
              "
            >
              <span className="mr-2 uppercase">Sign Up</span>
              <span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div className="flex justify-center items-center mt-6">
      <div
        className="
          flex flex-col
          items-center
          text-gray-700
          font-medium
          text-xs text-center
        "
      >

      {mailAlreadyUsed&& (
                <span className="visible ml-2 text-red-600 font-semibold">
                This email is already associated to an existing account. Please 
                <Link to='/login' className="text-xs ml-2 text-red-600 underline font-semibold">Login here</Link>
                </span>
      )}

      {missingPassword &&(
                <span className="text-xs text- ml-2 text-red-600 font-semibold">
                Please Enter a Password.
                </span>
      )}

      {weakPassword &&(
                <span className="text-xs text- ml-2 text-red-600 font-semibold">
                Password should be at least 6 characters.
                </span>
      )}

        <span className="ml-2">
            You already have an account?
            <Link to='/login' className="text-xs ml-2 text-[#00457c] font-semibold">Login here</Link>
        </span>
      </div>
    </div>

  </div>
  </div>
  )}

  {chargement && (<div className="h-screen flex justify-center items-center">
            {/* <ProgressSpinner/> */}
            
<span className='MailVerifSentence'>
  An email verification has been sent to you. You can now close this tab and go to your email inbox to click on the verification link. Thank you!
</span>

  </div>)}

  </div>

  )
}

export default SignUp