import React, { useState, useRef} from 'react'
import {signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import {auth} from "../firebase"
import { useNavigate } from 'react-router-dom';
import Nav from './Nav';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';

let compteur = 0;

function Login() {

    const [email,setMail] = useState("");
    const [password, setPassword] = useState("");
    const [showInvalidCredentials, setShowInvalidCredentials] = useState(false);
    const [forgottenPassword, setForgottenPasword] = useState(false);
    const [accountDisabled, setAccountDisabled] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const toast = useRef(null);

    const showSuccess = () => {
      toast.current.show({severity:'info', summary: 'Mail Sent', detail:'Check your mails and reset your password', life: 4000});
  }

    const Loginfr = (e) => {
    console.log("compteur" , compteur);
    e.preventDefault();
    setShowInvalidCredentials(false);
    setForgottenPasword(false);
    setAccountDisabled(false);
    console.log("mail: ", email , "password: ", password);
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
    // Signed in 

    console.log("userCredential : " ,userCredential);
    navigate('/GetYourAdvice');
  })
  .catch((error) => {
    if (compteur >= 3)
    {
      setForgottenPasword(true);
    }

    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("error : ", error);
    console.log("errorCode : ", errorCode);
    console.log("errorMessage : ", errorMessage);
    if(errorCode === "auth/invalid-login-credentials")
    {
      setShowInvalidCredentials(true);
      compteur = compteur + 1;
      console.log("compteur2 : ", compteur);
    }

    if(errorCode === "auth/too-many-requests")
    {
      setAccountDisabled(true);
      setForgottenPasword(true);
    }
  });
        }

  const resetPassword = (e) =>  {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      // ..
      showSuccess();
    })
    .catch((error) => {
      //const errorCode = error.code;
      //const errorMessage = error.message;
      console.log("error : ", error);
      // ..
    });
  }
    
  return (
    <div className='bg-gray-200'>
    <Nav></Nav>
    <Toast ref={toast} />
    <div className="min-h-screen flex flex-col items-center mt-10">
    <div
      className="
        flex flex-col
        bg-white
        shadow-md
        px-4
        sm:px-6
        md:px-8
        lg:px-10
        py-8
        rounded-3xl
        w-50
        max-w-md
      "
    >
      <div className="font-medium self-center text-xl sm:text-3xl text-gray-800">
        Login
      </div>
      <div className="mt-4 self-center text-xl sm:text-sm text-gray-800">
        Enter your credentials to get access to your account
      </div>

      <div className="mt-10">
        <form onSubmit={Loginfr}>

          <div className="flex flex-col mb-5">
            <label
              className="mb-1 text-xs tracking-wide text-gray-600"
              >E-Mail Address:</label
            >
            <div className="relative">
              <div
                className="
                  inline-flex
                  items-center
                  justify-center
                  absolute
                  left-0
                  top-0
                  h-full
                  w-10
                  text-gray-400
                "
              >

              </div>

              <input
                type="email"
                value={email}
                onChange={(e)=> setMail(e.target.value)}
                className="
                  text-sm
                  placeholder-gray-500
                  pl-10
                  pr-4
                  rounded-2xl
                  border border-gray-400
                  w-full
                  py-2
                  focus:outline-none focus:border-purple-800
                "
                placeholder="Enter your email"
              />
            </div>
          </div>
          <div className="flex flex-col mb-6">
            <label
              className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
              >Password:</label
            >
            <div className="relative">
              <div
                className="
                  inline-flex
                  items-center
                  justify-center
                  absolute
                  left-0
                  top-0
                  h-full
                  w-10
                  text-gray-400
                "
              >

              </div>

              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e)=> setPassword(e.target.value)}
                className="
                  text-sm
                  placeholder-gray-500
                  pl-10
                  pr-4
                  rounded-2xl
                  border border-gray-400
                  w-full
                  py-2
                  focus:outline-none focus:border-purple-800
                  relative
                "
                placeholder="Enter your password"
              />
              <button onClick={() => {setShowPassword(!showPassword)}}   className='cursor-pointer absolute right-4 top-1/2 -translate-y-1/2'>
                { showPassword &&(
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9CA3AF" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>)}
                { !showPassword && (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9CA3AF" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg>)}
              </button>
            </div>
          </div>

          <div className="flex w-full">
            <button
              type="submit"
              className="
                flex
                mt-2
                items-center
                justify-center
                focus:outline-none
                text-white text-sm
                sm:text-base
                bg-[#00457c]
                hover:bg-[#041e42]
                rounded-2xl
                py-2
                w-full
                transition
                duration-150
                ease-in
              "
            >
              <span className="mr-2 uppercase">login</span>
              <span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div className="flex justify-center items-center mt-6">
      <div
        className="
          flex flex-col
          items-center
          text-gray-700
          font-medium
          text-xs text-center
        "
      >
        
      </div>
    </div>

      <div
          className="
            flex flex-col
            items-center
            text-gray-700
            font-medium
            text-xs text-center
          "
        >

        {showInvalidCredentials&& (
                    <span className="text-xs ml-2 text-[#922d2d] font-semibold">
                    Invalid Login Credentials
                  </span>
        )}

        
        {accountDisabled&& (
                    <span className="text-xs ml-2 text-[#922d2d] font-semibold">
                    Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. 
                  </span>
        )}


        {forgottenPassword && (
                    <span className="text-xs ml-2 text-[#922d2d] font-semibold">
                    Password Forgotten ? <span className="text-xs ml-2 text-red-600 underline font-semibold cursor-pointer" onClick={resetPassword}>Reset It.</span>
                  </span>
        )}


          <span className="ml-2">
            You don't have an account?
            <Link to='/signup' className="text-xs ml-2 text-[#00457c] font-semibold">Sign Up here</Link>
          </span>



      </div>

  </div>
  </div>
  )
}

export default Login