import React from 'react'
import bannerImage from '../assets/banner.jpeg'
import './Banner.css'
import { Link } from 'react-router-dom'

function Banner() {


  return (
    <>
    <header className="banner" 
        style= {{
            backgroundSize: "cover",
            backgroundImage: `url(${bannerImage})` ,
            backgroundPosition: "center center",
            opacity: 1
        }}
        >
        
        {/* <div className="banner--fadeBottom"></div> */}
    </header>




    <div className="banner_contents">

            
            <h1 className="banner_title">
                "Because You Deserve Great Advices."
            </h1>

            {/* <p className="banner_text"></p> */}
            <Link to='/signup'>
                <button className='banner_button'>Ask For Advice
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e8d4a2" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                    </svg>
                </button>
            </Link>
    </div>
</>
  )
}

export default Banner